import { v4 as uuidv4 } from "uuid";
import * as ip from "ip6addr";

export function formatJSON(jsonString: string) {
  const data = JSON.parse(jsonString);
  return JSON.stringify(data, null, 2).replaceAll('],\n\t"', '],\n\n\t"');
}

export function normalizeString(value: string) {
  return value
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036F]/g, "");
}

export function generateRandomKey() {
  return uuidv4();
}

export function capitalize(value: string) {
  if (!value) return "";
  const valueString = value.toString();
  return valueString.charAt(0).toUpperCase() + valueString.slice(1);
}

export function isIPV6(str: string) {
  try {
    return ip.parse(str).kind() === "ipv6";
  } catch (error) {
    return false;
  }
}

export function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export function fileSizeInBytes(base64: string) {
  const base64String = base64.replaceAll("=", "");
  const bytes = base64String.length * (3 / 4);

  return bytes;
}
