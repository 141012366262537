/* eslint-disable max-lines */

import type { ActionTree, MutationTree } from "vuex";
import {
  SET_AGENCY_EVOLUTION_STATS,
  SET_AGENTS_PER_DAY_EVOLUTION_STATS,
  SET_ALERTS,
  SET_ALERTS_PER_DAY_EVOLUTION_STATS,
  SET_ALERTS_PER_VACCINE_EVOLUTION_STATS,
  SET_ALERT_EVOLUTION_STATS,
  SET_CIRCLES,
  SET_COMPUTER_EVOLUTION_STATS,
  SET_DOUGHNUTS,
  SET_IS_STATS_EVOLUTION_ERROR,
  SET_LOADING,
  SET_NEWS,
  SET_PYRAMIDS,
  SET_VACCINE_EVOLUTION_STATS,
  SET_VERSIONS_PER_AGENT_EVOLUTION_STATS,
} from "./dashboardMutationTypes";

import DashboardTransformer from "@/transformers/DashboardTransformer";
import { ALERT_STATUS } from "~/assets/js/constants";
import urlBuilder from "~/assets/js/urlBuilder";
import { AlertDTO } from "~/types/alert/alert";
import { NewsItemDTO } from "~/types/newsItem/newsItem";
import { PaginatedResponse } from "~/types/types";

export const state = () => ({
  alerts: null as PaginatedResponse<AlertDTO>,
  newsItems: null as PaginatedResponse<NewsItemDTO>,
  circles: [],
  doughnuts: [],
  computerEvolutionStats: { labels: [], datasets: [{ label: [], data: [] }] },
  alertEvolutionStats: { labels: [], datasets: [{ label: [], data: [] }] },
  vaccineEvolutionStats: { labels: [], datasets: [{ label: [], data: [] }] },
  agencyEvolutionStats: { labels: [], datasets: [{ label: [], data: [] }] },
  alertsPerDayEvolutionStats: { labels: [], datasets: [{ label: [], data: [] }] },
  agentsPerDayEvolutionStats: { labels: [], datasets: [{ label: [], data: [] }] },
  alertsPerVaccineEvolutionStats: { labels: [], datasets: [{ label: [], data: [] }] },
  versionsPerAgentEvolutionStats: { labels: [], datasets: [{ label: [], data: [] }] },
  loading: false,
  isStatsEvolutionError: false,
  pyramids: [],
  totalAlerts: 0,
  lastAlertsLength: 6,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_ALERTS](state, alerts: PaginatedResponse<AlertDTO>) {
    state.alerts = alerts;
  },
  [SET_NEWS](state, newsItems: PaginatedResponse<NewsItemDTO>) {
    state.newsItems = newsItems;
  },
  [SET_CIRCLES](state, circles) {
    state.circles = circles;
  },
  [SET_DOUGHNUTS](state, doughnuts) {
    this._vm.$set(state, "doughnuts", doughnuts);
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_PYRAMIDS](state, pyramids) {
    state.pyramids = pyramids;
  },
  [SET_COMPUTER_EVOLUTION_STATS](state, computerEvolutionStats) {
    state.computerEvolutionStats = computerEvolutionStats;
  },
  [SET_ALERT_EVOLUTION_STATS](state, alertEvolutionStats) {
    state.alertEvolutionStats = alertEvolutionStats;
  },
  [SET_ALERTS_PER_DAY_EVOLUTION_STATS](state, alertsPerDayEvolutionStats) {
    state.alertsPerDayEvolutionStats = alertsPerDayEvolutionStats;
  },
  [SET_AGENTS_PER_DAY_EVOLUTION_STATS](state, agentsPerDayEvolutionStats) {
    state.agentsPerDayEvolutionStats = agentsPerDayEvolutionStats;
  },
  [SET_VACCINE_EVOLUTION_STATS](state, vaccineEvolutionStats) {
    state.vaccineEvolutionStats = vaccineEvolutionStats;
  },
  [SET_AGENCY_EVOLUTION_STATS](state, agencyEvolutionStats) {
    state.agencyEvolutionStats = agencyEvolutionStats;
  },
  [SET_ALERTS_PER_VACCINE_EVOLUTION_STATS](state, alertsPerVaccineEvolutionStats) {
    state.alertsPerVaccineEvolutionStats = alertsPerVaccineEvolutionStats;
  },
  [SET_VERSIONS_PER_AGENT_EVOLUTION_STATS](state, versionsPerAgentEvolutionStats) {
    state.versionsPerAgentEvolutionStats = versionsPerAgentEvolutionStats;
  },
  [SET_IS_STATS_EVOLUTION_ERROR](state, value: boolean) {
    state.isStatsEvolutionError = value;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetch({ dispatch, commit, rootGetters }) {
    dispatch("setLoading", true);
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    await dispatch("updateDoughnuts");
    await dispatch("updateCircles");
    await dispatch("updateLastAlerts");
    await dispatch("updateLatestNews");
    await dispatch("updatePyramids");
    await dispatch("instances/setInstances", {}, { root: true });

    try {
      if (!agencyId) {
        await dispatch("updateEvolutionStats", 7); // solo main
      }
    } catch (e) {
      console.error(e);
      this.$feedback.error("generic.evolutionStatsDataError");
      commit("SET_IS_STATS_EVOLUTION_ERROR", true);
    }
    dispatch("setLoading", false);
  },
  setLoading({ commit }, loading) {
    commit(SET_LOADING, loading);
  },
  async updateCircles({ commit, rootGetters }) {
    const circles = [];

    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const endpoint = agencyId ? `stats/${agencyId}/totals` : "stats/totals";

    await this.$axios
      .get(endpoint)
      .then((res) => {
        if (rootGetters["application/isAdmin"] && !agencyId) {
          circles.push(
            DashboardTransformer.circle({
              index: 0,
              title: "dashboard.charts.totalAlerts.subtitle",
              subtitle: "dashboard.charts.totalAlerts.title",
              value: res.data.alerts,
            }),
          );
          circles.push(
            DashboardTransformer.circle({
              index: 3,
              title: "dashboard.charts.totalAgencies.subtitle",
              subtitle: "dashboard.charts.totalAgencies.title",
              value: res.data.agencies,
            }),
          );
        } else {
          circles.push(
            DashboardTransformer.circle({
              index: 0,
              title: "dashboard.charts.totalAlerts.subtitle",
              subtitle: "dashboard.charts.totalAlerts.title",
              value: res.data.alerts,
            }),
          );
        }
        circles.push(
          DashboardTransformer.circle({
            index: 1,
            title: "dashboard.charts.totalComputers.subtitle",
            subtitle: "dashboard.charts.totalComputers.title",
            value: res.data.agents,
          }),
        );
        circles.push(
          DashboardTransformer.circle({
            index: 2,
            title: "dashboard.charts.totalVaccines.subtitle",
            subtitle: "dashboard.charts.totalVaccines.title",
            value: res.data.vaccines,
          }),
        );
        commit(SET_CIRCLES, circles);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.debug(e);
      });
  },
  async updateEvolutionStats({ commit, rootGetters }, range) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const stats = await this.$axios.$get(`${url}stats/evolution/${range}`);
    commit(SET_COMPUTER_EVOLUTION_STATS, DashboardTransformer.computerEvolutionCollection(stats));
    commit(SET_VACCINE_EVOLUTION_STATS, DashboardTransformer.vaccineEvolutionCollection(stats));
    commit(SET_ALERT_EVOLUTION_STATS, DashboardTransformer.alertEvolutionCollection(stats));
    commit(SET_AGENCY_EVOLUTION_STATS, DashboardTransformer.agencyEvolutionCollection(stats));
    commit(
      SET_ALERTS_PER_DAY_EVOLUTION_STATS,
      DashboardTransformer.alertsPerDayEvolutionCollection(stats),
    );
    commit(
      SET_AGENTS_PER_DAY_EVOLUTION_STATS,
      DashboardTransformer.agentsPerDayEvolutionCollection(stats),
    );
    commit(
      SET_ALERTS_PER_VACCINE_EVOLUTION_STATS,
      DashboardTransformer.alertsPerVaccineEvolutionCollection(stats),
    );
    commit(
      SET_VERSIONS_PER_AGENT_EVOLUTION_STATS,
      DashboardTransformer.agentsPerVersionEvolutionCollection(stats),
    );
  },

  async updateComputerEvolutionStats({ commit, rootGetters }, range) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const stats = await this.$axios.$get(`${url}stats/evolution/${range}`);
    commit(SET_COMPUTER_EVOLUTION_STATS, DashboardTransformer.computerEvolutionCollection(stats));
  },

  async updateVaccineEvolutionStats({ commit, rootGetters }, range) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const stats = await this.$axios.$get(`${url}stats/evolution/${range}`);
    commit(SET_VACCINE_EVOLUTION_STATS, DashboardTransformer.vaccineEvolutionCollection(stats));
  },

  async updateAlertEvolutionStats({ commit, rootGetters }, range) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const stats = await this.$axios.$get(`${url}stats/evolution/${range}`);
    commit(SET_ALERT_EVOLUTION_STATS, DashboardTransformer.alertEvolutionCollection(stats));
  },

  async updateAlertsPerDayEvolutionStats({ commit, rootGetters }, range) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const stats = await this.$axios.$get(`${url}stats/evolution/${range}`);
    commit(
      SET_ALERTS_PER_DAY_EVOLUTION_STATS,
      DashboardTransformer.alertsPerDayEvolutionCollection(stats),
    );
  },

  async updateAgentsPerVersionEvolutionStats({ commit }, range) {
    const stats = await this.$axios.$get(`stats/evolution/${range}`);
    commit(
      SET_VERSIONS_PER_AGENT_EVOLUTION_STATS,
      DashboardTransformer.agentsPerVersionEvolutionCollection(stats),
    );
  },
  async updateAgencyEvolutionStats({ commit, rootGetters }, range) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const stats = await this.$axios.$get(`${url}stats/evolution/${range}`);
    commit(SET_AGENCY_EVOLUTION_STATS, DashboardTransformer.agencyEvolutionCollection(stats));
  },

  async updateAgentsPerDayEvolutionStats({ commit, rootGetters }, range) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const stats = await this.$axios.$get(`${url}stats/evolution/${range}`);
    commit(
      SET_AGENTS_PER_DAY_EVOLUTION_STATS,
      DashboardTransformer.agentsPerDayEvolutionCollection(stats),
    );
  },

  async updateAlertsPerVaccineEvolutionStats({ commit, rootGetters }, range) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const stats = await this.$axios.$get(`${url}stats/evolution/${range}`);
    commit(
      SET_ALERTS_PER_VACCINE_EVOLUTION_STATS,
      DashboardTransformer.alertsPerVaccineEvolutionCollection(stats),
    );
  },

  async updateDoughnuts({ commit, rootGetters }) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const url = agencyId ? `agencies/${agencyId}/` : "";

    const doughnuts = [];
    if (rootGetters["application/isAdmin"] && !agencyId) {
      await this.$axios
        .get("stats/deployments/count?groupBy=sector&size=5")
        .then((res) => {
          doughnuts.push(
            DashboardTransformer.doughnutsCollection(res.data, {
              title: "dashboard.charts.bysector.title",
              measure: this.$i18n.t("computers.label"),
              emptyMessage: "dashboard.charts.bysector.error",
            }),
          );
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.debug(e);
        });
      await this.$axios
        .get("stats/deployments/count?groupBy=agency&size=5")
        .then((res) => {
          doughnuts.push(
            DashboardTransformer.doughnutsCollection(res.data, {
              title: "dashboard.charts.byagency.title",
              measure: this.$i18n.t("computers.label"),
              emptyMessage: "dashboard.charts.byagency.error",
            }),
          );
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.debug(e);
        });
      await this.$axios.get("stats/agents/count?groupBy=offline&size=5").then((res) => {
        doughnuts.push(
          DashboardTransformer.doughnutsCollection(res.data, {
            title: "dashboard.charts.offline.title",
            measure: this.$i18n.t("computers.label"),
            emptyMessage: "dashboard.charts.offline.error",
            alert: false,
          }),
        );
      });
      await this.$axios
        .get("stats/agents/count?groupBy=version&size=5")
        .then((res) => {
          doughnuts.push(
            DashboardTransformer.doughnutsCollection(res.data, {
              title: "dashboard.charts.versionall.title",
              measure: this.$i18n.t("computers.label"),
              emptyMessage: "dashboard.charts.versionall.error",
              alert: false,
              wilcardKey: "others",
              wilcardKeyTranslation: this.$i18n.t("generic.others"),
            }),
          );
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.debug(e);
        });
    } else {
      await this.$axios
        .get(`${url}stats/agents/count?groupBy=status&size=5`)
        .then((res) => {
          doughnuts.push(
            DashboardTransformer.doughnutsCollection(res.data, {
              title: "dashboard.charts.status.title",
              emptyMessage: "dashboard.charts.status.error",
              measure: this.$i18n.t("computers.label"),
            }),
          );
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.debug(e);
        });
      await this.$axios
        .get(`${url}stats/agents/count?groupBy=version&size=5`)
        .then((res) => {
          doughnuts.push(
            DashboardTransformer.doughnutsCollection(res.data, {
              title: "dashboard.charts.version.title",
              emptyMessage: "dashboard.charts.version.error",
              measure: this.$i18n.t("computers.label"),
            }),
          );
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.debug(e);
        });
      await this.$axios
        .get(`${url}stats/agents/count?groupBy=os&size=5`)
        .then((res) => {
          doughnuts.push(
            DashboardTransformer.doughnutsCollection(res.data, {
              title: "dashboard.charts.so.title",
              emptyMessage: "dashboard.charts.so.error",
              measure: this.$i18n.t("computers.label"),
              alert: false,
            }),
          );
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.debug(e);
        });
    }
    commit(SET_DOUGHNUTS, doughnuts);
  },
  async updateLatestNews({ commit }) {
    try {
      const newsItems: PaginatedResponse<NewsItemDTO> = await this.$axios.$get("/stats/news");
      commit(SET_NEWS, newsItems);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updateLastAlerts({ state, commit, rootGetters }) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const url = agencyId ? `agencies/${agencyId}/alerts` : "alerts";

    const apiUrl = urlBuilder(url, {
      size: state.lastAlertsLength,
      filter: `status:${ALERT_STATUS.ACTIVE}`,
    });

    try {
      const lastAlerts: PaginatedResponse<AlertDTO> = await this.$axios.$get(apiUrl);
      commit(SET_ALERTS, lastAlerts);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updatePyramids({ commit, rootGetters }) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const pyramids = [];
    if (rootGetters["application/isAdmin"] && !agencyId) {
      try {
        let apiUrl = urlBuilder("stats/alerts/count", {
          groupBy: "sector",
          size: 8,
        });

        let res = await this.$axios.$get(apiUrl);
        pyramids.push(
          DashboardTransformer.pyramidsCollection(
            res,
            "dashboard.charts.alertsBySector.title",
            "dashboard.charts.alertsBySector.error",
            false,
          ),
        );

        apiUrl = urlBuilder("stats/alerts/count", {
          groupBy: "agency",
          size: 8,
        });

        res = await this.$axios.$get(apiUrl);
        pyramids.push(
          DashboardTransformer.pyramidsCollection(
            res,
            "dashboard.charts.alertsByAgency.title",
            "dashboard.charts.alertsByAgency.error",
            false,
          ),
        );
      } catch (error) {
        throw new Error(error);
      }
    } else {
      const enpoint = agencyId ? `agencies/${agencyId}/stats/alerts/count` : "stats/alerts/count";

      try {
        const apiUrl = urlBuilder(enpoint, {
          groupBy: "computer",
          size: 8,
        });

        const res = await this.$axios.$get(apiUrl);
        pyramids.push(
          DashboardTransformer.pyramidsCollection(
            res,
            "dashboard.charts.alertsByComputer.title",
            "dashboard.charts.alertsByComputer.error",
            false,
          ),
        );
      } catch (error) {
        throw new Error(error);
      }
    }

    try {
      const enpoint = agencyId ? `agencies/${agencyId}/stats/alerts/count` : "stats/alerts/count";

      const apiUrl = urlBuilder(enpoint, {
        groupBy: "vaccine",
        size: 8,
      });
      const res = await this.$axios.$get(apiUrl);
      pyramids.push(
        DashboardTransformer.pyramidsCollection(
          res,
          "dashboard.charts.alertsByVaccine.title",
          "dashboard.charts.alertsByVaccine.error",
          false,
        ),
      );
    } catch (error) {
      return new Error(error);
    }

    commit(SET_PYRAMIDS, pyramids);
  },
};
