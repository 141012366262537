import { INSTANCE_TYPE } from "~/assets/js/constants";

export default class NavigationTransformer {
  static fetchFederated(items) {
    return items.collection
      .filter((item) => item.type !== INSTANCE_TYPE.MAIN)
      .map((item) => {
        const { id, name } = item;
        return {
          icon: "network",
          textLink: name,
          link: `/federated/${id}`,
          admin: true,
        };
      });
  }
}
