/* eslint-disable max-lines */

import type { ActionTree, MutationTree } from "vuex";
import { AgentDTO, AgentSaveDTO } from "~/types/agent/agent";
import { ComputerDTO, TagsDTO } from "~/types/computer/computer";
import { PaginatedResponse, RequestConfigParams, agencyid, uuid4 } from "~/types/types";
import {
  DELETE_ALL_COMPUTERS,
  DELETE_COMPUTER,
  MARK_FOR_REMOVAL,
  REMOVE_ALL_VACCINE_DEPLOYMENTS,
  REMOVE_SELECTED_COMPUTERS,
  REMOVE_VACCINE_DEPLOYMENTS,
  RESET_CURRENT_COMPUTER,
  SET_AGENCY,
  SET_COMPUTER,
  SET_COMPUTERS,
  SET_CURRENT_COMPUTER,
  SET_DEPLOYMENT_STATUS,
  SET_DEPLOYMENT_VACCINATED_STATUS,
  SET_TAGS,
  SET_VACCINATED_STATUS,
  SET_VACCINE_DEPLOYMENTS,
  UNINSTALL_SELECTED_COMPUTERS,
} from "./computersMutationTypes";

import { lifecycleToLifecycle } from "~/assets/js/logic/lifecycleToLifecycle";
import urlBuilder from "~/assets/js/urlBuilder";
import DownloadService from "~/services/DownloadService";
import { VaccineDeploymentDTO } from "~/types/deployment/vaccineDeployment";
import { SET_LOADING } from "../sharedMutationTypes";

export const state = () => ({
  agency: null,
  computers: null as PaginatedResponse<AgentDTO>,
  vaccineDeployments: {} as { [key: string]: PaginatedResponse<VaccineDeploymentDTO> },
  currentComputer: null as ComputerDTO,
  folded: [],
  filtered: [],
  loading: false,
  paginator: {
    size: 22,
    subtable: {
      size: 10,
    },
  },
  searchBody: [],
  subtables: [],
  tags: [] as string[],
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_VACCINATED_STATUS](state, agents: AgentDTO[]) {
    const computers = state.computers.collection;
    for (const agent of agents) {
      const index = computers.findIndex((computer) => {
        return computer.id === agent.id;
      });
      const isComputerFound = index >= 0;
      if (isComputerFound) {
        computers.splice(index, 1, agent);
      }
    }
  },

  [SET_DEPLOYMENT_STATUS](state, args: { agentId: uuid4; vaccineIds: uuid4[] }) {
    const { agentId, vaccineIds } = args;
    const deploymentsCollection = state.vaccineDeployments[agentId].collection;
    const index = deploymentsCollection.findIndex((deployment) => {
      return deployment.id === vaccineIds[0];
    });
    const isDeploymentFound = index >= 0;

    if (isDeploymentFound) {
      const deployment = deploymentsCollection[index];

      const newStatus = lifecycleToLifecycle(deployment.status);

      deployment.status = newStatus;
      deploymentsCollection.splice(index, 1, deployment);
    }
  },

  [SET_DEPLOYMENT_VACCINATED_STATUS](state, args: { agentIds: uuid4[]; vaccineId: uuid4 }) {
    const { agentIds, vaccineId } = args;
    for (const agentId of agentIds) {
      const deploymentsCollection = state.vaccineDeployments[agentId]?.collection;

      if (deploymentsCollection) {
        const index = deploymentsCollection.findIndex((deployment) => {
          return deployment.id === vaccineId;
        });
        const isDeploymentFound = index >= 0;

        if (isDeploymentFound) {
          const deployment = deploymentsCollection[index];

          const newStatus = lifecycleToLifecycle(deployment.status);

          deployment.status = newStatus;
          deploymentsCollection.splice(index, 1, deployment);
        }
      }
    }
  },

  [DELETE_COMPUTER](state, id: uuid4) {
    const index = state.computers.collection.findIndex((computer) => computer.id === id);
    const isComputerFound = index >= 0;

    if (isComputerFound) {
      state.computers.collection.splice(index, 1);
    }
  },
  [REMOVE_SELECTED_COMPUTERS](state, selection: uuid4[]) {
    state.computers.collection = state.computers.collection.filter(
      (computer) => !selection.includes(computer.id),
    );
  },
  [UNINSTALL_SELECTED_COMPUTERS](state, selection: uuid4[]) {
    for (const computer of state.computers.collection) {
      if (selection.includes(computer.id)) {
        computer.markedForRemoval = true;
      }
    }
  },
  [SET_CURRENT_COMPUTER](state, currentComputer: ComputerDTO) {
    state.currentComputer = currentComputer;
  },
  [RESET_CURRENT_COMPUTER](state) {
    state.currentComputer = null;
  },
  [DELETE_ALL_COMPUTERS](state) {
    state.computers = null;
  },
  [SET_AGENCY](state, agency) {
    state.agency = agency;
  },
  [SET_COMPUTER](state, updatedComputer: AgentDTO) {
    const index = state.computers.collection.findIndex(
      (computer) => computer.id === updatedComputer.id,
    );

    if (index >= 0) {
      state.computers.collection.splice(index, 1, updatedComputer);
    }
  },

  [MARK_FOR_REMOVAL](state, id: uuid4) {
    const index = state.computers.collection.findIndex((agent) => agent.id === id);

    if (index >= 0) {
      const updatedAgent: AgentDTO = {
        ...state.computers.collection[index],
        markedForRemoval: true,
      };
      state.computers.collection.splice(index, 1, updatedAgent);
    }
  },

  [SET_COMPUTERS](state, agents: PaginatedResponse<AgentDTO>) {
    state.computers = agents;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [REMOVE_VACCINE_DEPLOYMENTS](state, { parent }: { parent: uuid4 }) {
    this._vm.$delete(state.vaccineDeployments, parent);
  },

  [REMOVE_ALL_VACCINE_DEPLOYMENTS](state) {
    for (const parent of Object.keys(state.vaccineDeployments)) {
      this._vm.$delete(state.vaccineDeployments, parent);
    }
  },

  [SET_VACCINE_DEPLOYMENTS](
    state,
    {
      parent,
      vaccineDeployments,
    }: { parent: uuid4; vaccineDeployments: PaginatedResponse<VaccineDeploymentDTO> },
  ) {
    this._vm.$set(state.vaccineDeployments, parent, vaccineDeployments);
  },

  [SET_TAGS](state, tags: TagsDTO) {
    state.tags = tags.tags;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async setCurrentComputer({ commit }, id: uuid4) {
    try {
      const currentComputer = await this.$axios.$get<ComputerDTO>(`agents/${id}`);
      commit(SET_CURRENT_COMPUTER, currentComputer);
    } catch (error) {
      if (error.response.data.message === "agent.notfound") {
        this.$feedback.error("feedback.api.error.agent.notfound");
      } else {
        throw new Error(error);
      }
    }
  },

  async resetCurrentComputer({ commit }) {
    commit(RESET_CURRENT_COMPUTER);
  },

  removeAllVaccineDeployments({ commit }) {
    commit(REMOVE_ALL_VACCINE_DEPLOYMENTS);
  },

  async vaccinateSelectedComputers(
    { commit },
    { vaccineId, selection }: { vaccineId: uuid4; selection: uuid4[] },
  ) {
    const endpoint = `/vaccines/${vaccineId}/agents/apply`;

    try {
      const updatedAgents = await this.$axios.$put(endpoint, selection);
      commit(SET_VACCINATED_STATUS, updatedAgents);
      commit(SET_DEPLOYMENT_VACCINATED_STATUS, { agentIds: selection, vaccineId });
      this.$feedback.ok("computers.globalActions.vaccinateSelectedAgents.ok");
    } catch (error) {
      this.$feedback.error("computers.globalActions.vaccinateSelectedAgents.error");
    }
  },

  async executeVaccine({ commit }, { vaccineId, agentId }: { vaccineId: uuid4; agentId: uuid4 }) {
    const executables: uuid4[] = [vaccineId];
    const endpoint = `agents/${agentId}/vaccines/${vaccineId}/apply`;

    try {
      await this.$axios.$put(endpoint, executables);
      commit(SET_DEPLOYMENT_STATUS, {
        agentId,
        vaccineIds: executables,
      });
      this.$feedback.ok("feedback.execute.vaccine.ok");
    } catch (error) {
      this.$feedback.error("feedback.execute.vaccine.error");
    }
  },
  async stopVaccine({ commit }, { vaccineId, agentId }: { vaccineId: uuid4; agentId: uuid4 }) {
    const executables: uuid4[] = [vaccineId];
    const endpoint = `agents/${agentId}/vaccines/${vaccineId}/stop`;

    try {
      await this.$axios.$put(endpoint, executables);
      commit(SET_DEPLOYMENT_STATUS, {
        agentId,
        vaccineIds: executables,
      });
      this.$feedback.ok("feedback.stop.vaccine.ok");
    } catch (error) {
      this.$feedback.error("feedback.stop.vaccine.error");
    }
  },

  async uninstallAllAgents({ commit, rootGetters, state }) {
    try {
      const pageSize = rootGetters["application/getPageSize"];
      const updateComputers = await this.$axios.$put(`/agencies/${state.agency}/agents/uninstall`, {
        pageSize,
      });
      commit(SET_COMPUTERS, updateComputers);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async deleteAllComputers({ commit, state }) {
    try {
      await this.$axios.delete(`/agencies/${state.agency}/agents`);
      commit(DELETE_ALL_COMPUTERS);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async deleteComputer({ commit }, computerId: uuid4) {
    try {
      await this.$axios.delete(`agents/${computerId}`);
      commit(DELETE_COMPUTER, computerId);
    } catch (error) {
      throw new Error(error);
    }
  },

  async uninstallAgent({ commit }, { agentId }: { agentId: uuid4 }) {
    try {
      await this.$axios.put(`/agents/${agentId}/uninstall`);
      commit(MARK_FOR_REMOVAL, agentId);
    } catch (error) {
      throw new Error(error);
    }
  },

  async moveSelectedComputers(
    { rootGetters, commit },
    { destination, selection }: { destination: agencyid; selection: uuid4[] },
  ) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const apiUrl = `/agencies/${agencyId}/agents/move`;
    try {
      await this.$axios.$post(apiUrl, { agencyId: destination, agents: selection });
      commit(REMOVE_SELECTED_COMPUTERS, selection);
      this.$feedback.ok("computers.globalActions.move.ok");
    } catch (error) {
      this.$feedback.error("computers.globalActions.move.error");
    }
  },

  async deleteSelectedComputers({ rootGetters, commit }, { selection }: { selection: uuid4[] }) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const apiUrl = `/agencies/${agencyId}/agents/bulk/delete`;
    try {
      await this.$axios.$post(apiUrl, { agents: selection });
      commit(REMOVE_SELECTED_COMPUTERS, selection);
      this.$feedback.ok("feedback.delete.computers.bulk.ok");
    } catch (error) {
      this.$feedback.error("feedback.delete.computers.bulk.error");
    }
  },

  async uninstallSelectedComputers({ rootGetters, commit }, { selection }: { selection: uuid4[] }) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const apiUrl = `/agencies/${agencyId}/agents/bulk/uninstall`;
    try {
      await this.$axios.$post(apiUrl, { agents: selection });
      commit(UNINSTALL_SELECTED_COMPUTERS, selection);
      this.$feedback.ok("feedback.uninstall.computers.bulk.ok");
    } catch (error) {
      this.$feedback.error("feedback.uninstall.computers.bulk.error");
    }
  },

  async moveAllComputers({ rootGetters, dispatch }, { destination }) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const apiUrl = `/agencies/${agencyId}/agents/moveAll`;
    try {
      await this.$axios.$post(apiUrl, { agencyId: destination });
      await dispatch("updateComputers");
      this.$feedback.ok("computers.globalActions.move.ok");
    } catch (error) {
      this.$feedback.error("computers.globalActions.move.error");
    }
  },

  async prepareCsv({ rootGetters, commit, dispatch }, requestParams: RequestConfigParams) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const endpoint = `/agencies/${agencyId}/agents/preparecsv`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },

  async fetch({ dispatch, commit }, params) {
    commit(SET_LOADING, true);
    try {
      const { agency } = params;
      commit(SET_AGENCY, agency);
      await dispatch("setComputers");
      await dispatch("updateTags");
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async setComputer(
    { commit, dispatch },
    { savedAgent, id }: { savedAgent: AgentSaveDTO; id: uuid4 },
  ) {
    try {
      const updatedAgent: AgentDTO = await this.$axios.patch(`agents/${id}`, {
        tags: savedAgent.tags,
      });
      commit(SET_COMPUTER, updatedAgent);
      await dispatch("updateTags");
      this.$feedback.ok("feedback.update.computer.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.computer.error");
    }
  },

  async setComputers({ state, commit, rootGetters }, requestParams: RequestConfigParams = {}) {
    const { sortBy, orderBy, page, filter, defaultFilter } = requestParams;
    const { agency: agencyId } = state;
    const size = rootGetters["application/getPageSize"];

    const apiUrl = urlBuilder(`agencies/${agencyId}/agents`, {
      size,
      page,
      sortBy,
      orderBy,
      filter,
      defaultFilter,
    });

    try {
      const agents: PaginatedResponse<AgentDTO> = await this.$axios.$get(apiUrl);
      commit(SET_COMPUTERS, agents);
    } catch (error) {
      throw new Error(error);
    }
  },

  async fetchVaccineDeployments(
    { commit },
    args: { agentId: uuid4; requestParams: RequestConfigParams },
  ) {
    const { agentId, requestParams } = args;
    const { page } = requestParams;
    const size = 10;

    const endpoint = `agents/${agentId}/deployments`;
    const apiUrl = urlBuilder(endpoint, {
      size,
      page,
    });

    try {
      const vaccineDeployments: PaginatedResponse<VaccineDeploymentDTO> = await this.$axios.$get(
        apiUrl,
      );
      commit(SET_VACCINE_DEPLOYMENTS, { parent: agentId, vaccineDeployments });
    } catch (error) {
      throw new Error(error);
    }
  },

  async removeVaccineDeployments({ commit }, agentId: uuid4) {
    commit(REMOVE_VACCINE_DEPLOYMENTS, { parent: agentId });
  },

  async updateTags({ state, commit }) {
    const { agency } = state;

    try {
      const tags: TagsDTO = await this.$axios.$get(`/agencies/${agency}/tags`);

      commit(SET_TAGS, tags);
    } catch (error) {
      throw new Error(error);
    }
  },
};
