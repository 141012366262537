//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "AsideLink",
  props: {
    action: {
      type: String,
      default: "",
    },
    eventHandler: {
      type: Function,
      required: false,
    },
    icon: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    button: {
      type: Boolean,
      default: false,
    },
    popover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popoverOpened: false,
      loading: false,
    };
  },
  methods: {
    async onClick() {
      if (this.action) {
        this.loading = true;
        await this.$store.dispatch(this.action);
        this.loading = false;
      }
      this.$emit("link-clicked", this.eventHandler);
    },
  },
};
