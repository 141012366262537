//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { DialogsWrapper } from "vue-modal-dialogs";
import { mapActions, mapGetters, mapState } from "vuex";
import { AUTH_STATE, TIME } from "~/assets/js/constants";
import TheAside from "~/components/aside/TheAside.vue";
import Feedback from "~/components/feedback/Feedback.vue";
import TheNav from "~/components/nav/main/TheNav.vue";
import SecondaryNav from "~/components/nav/secondary/SecondaryNav.vue";

export default {
  name: "MainLayout",
  components: {
    Feedback,
    TheAside,
    TheNav,
    SecondaryNav,
    DialogsWrapper,
  },
  middleware: [
    "authenticated",
    async ({ store }) => {
      await store.dispatch("application/updateMainNav");
      await store.dispatch("application/setEnv");
    },
  ],
  data() {
    return {
      secondaryNav: [
        {
          icon: "tachometter",
          link: "",
        },
        {
          icon: "computer",
          link: "computers",
          visible: true,
        },
        {
          icon: "syringe",
          link: "vaccines",
          visible: true,
        },
        {
          icon: "alert",
          link: "alerts",
        },
      ],
      isSidePanelOpen: false,
    };
  },
  computed: {
    ...mapState("application", [
      "loggedUser",
      "loggedRole",
      "selectedAgency",
      "mainInstanceId",
      "panel",
      "downloads",
      "isWaitingForDownloads",
    ]),
    ...mapGetters({
      mainNav: "application/getMainNav",
      loggedRole: "application/getLoggedRole",
      isSingleAgencyUser: "application/isSingleAgencyUser",
      areDownloadsCompleted: "application/areDownloadsCompleted",
      isInAgency: "application/isInAgency",
      twoFAState: "authentication/getTwoFAState",
    }),
    secondaryNavLinks() {
      if (this.isSingleAgencyUser) {
        return [
          {
            icon: "computer",
            link: "computers",
            visible: true,
          },
          {
            icon: "syringe",
            link: "vaccines",
            visible: true,
          },
          {
            icon: "hashtag-light",
            link: "telemetry",
            visible: true,
          },
        ];
      } else {
        return [
          {
            icon: "tachometter",
            link: "",
          },
          {
            icon: "computer",
            link: "computers",
            visible: true,
          },
          {
            icon: "syringe",
            link: "vaccines",
            visible: true,
          },
          {
            icon: "alert",
            link: "alerts",
          },
          {
            icon: "hashtag-light",
            link: "telemetry",
            visible: true,
          },
        ];
      }
    },
    isPreAuthCode() {
      return this.twoFAState === AUTH_STATE.ROLE_PRE_AUTH_CODE;
    },
    isPreAuthSecret() {
      return this.twoFAState === AUTH_STATE.ROLE_PRE_AUTH_SECRET;
    },
    isSecondaryNavVisible() {
      if (this.isSingleAgencyUser) {
        return false;
      }
      return this.isInAgency && this.$nuxt.$route.name.search("agencies-agency") !== -1;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateVersion);
  },
  mounted() {
    window.addEventListener("resize", this.updateVersion);
  },
  created() {
    this.setDownloads();
    setInterval(() => {
      if (this.isWaitingForDownloads && !this.areDownloadsCompleted) {
        this.setDownloads();
      }
    }, TIME.SECOND * 2);

    if (this.isPreAuthCode || this.isPreAuthSecret) {
      this.logout();
    }
  },
  methods: {
    ...mapActions({
      updateVersion: "application/updateVersion",
      setDownloads: "application/setDownloads",
      setFederatedView: "application/setFederatedView",
      logout: "authentication/logout",
    }),
    onPanelClose() {
      this.isSidePanelOpen = false;
    },
    onTechSupportPanelOpen() {
      this.isSidePanelOpen = true;
    },
    transitionEnter(el, done) {
      el.style.top = `-${el.firstChild.offsetHeight}px`;
      this.$velocity(el, { top: "0px" }, { duration: 250, easing: "ease-in-out", complete: done });
    },
    transitionLeave(el, done) {
      this.$velocity(
        el,
        { top: `-${el.firstChild.offsetHeight}px` },
        { duration: 250, easing: "ease-in-out", complete: done },
      );
    },
  },
};
