// @ts-check
export const SELECTION_STATE = Object.freeze({
  EXCLUDED: 1,
  UNSELECTED: -1,
  UNCHECKED: 0,
  CHECKED: 3,
  SUPERCHECKED: 2,
});

export const VACCINATION_STATES = [
  "bg-status-error", // ok
  "bg-status-ok", // ok
  "bg-status-warning-light", // ok
  "bg-primary-light", // ??
  "bg-status-error-dark", // ok
  "bg-status-error-dark", // ok
  "bg-status-ok-striped", // ??
  "bg-status-warning-striped", // ??
];

/**
 * @readonly
 * @enum {string}
 */
export const INCLUSION_STATE = Object.freeze({
  EXCLUDED: 1,
  INCLUDED: 0,
});

export enum PANEL {
  ADMIN = "admin-panel",
  USER = "user-panel",
}

/**
 * @readonly
 * @enum {string}
 */
export const FEEDBACK = Object.freeze({
  ERROR: 1,
  ALERT: 2,
  OK: 3,
});

// export enum LIFECYCLE_STATE {
//   NOT_APPLIED_NOT_REQUIRED = 3,
//   NOT_APPLIED_REQUIRED = 0,
//   APPLIED_REQUIRED = 1,
//   APPLIED_NOT_REQUIRED = 2,
//   APPLIED_WITH_ERROR_REQUIRED = 4,
//   APPLIED_WITH_ERROR_NOT_REQUIRED = 5,
//   PENDING_REQUIRED = 6,
//   PENDING_NOT_REQUIRED = 7,
// }

// export enum LIFECYCLE_STATE {
//   NOT_APPLIED_NOT_REQUIRED = 3,
//   NOT_APPLIED_REQUIRED = 0,
//   APPLIED = 2,
//   APPLIED_WITH_ERROR = 5,
//   PENDING = 7,
// }

export enum LIFECYCLE_STATE {
  NOT_APPLIED_REQUIRED = 0,
  APPLIED_REQUIRED_LEGACY = 1,
  APPLIED_NOT_REQUIRED_LEGACY = 2,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  APPLIED = 2,
  NOT_APPLIED_NOT_REQUIRED = 3,
  APPLIED_WITH_ERROR_REQUIRED_LEGACY = 4,
  APPLIED_WITH_ERROR_NOT_REQUIRED_LEGACY = 5,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  APPLIED_WITH_ERROR = 5,
  PENDING_REQUIRED_LEGACY = 6,
  PENDING_NOT_REQUIRED_LEGACY = 7,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PENDING = 7,
  INCOMPATIBLE = 10,
}

export enum INSTANCE_TYPE {
  MAIN = 1,
  FEDERATED = 2,
  CLASSIFIED = 3,
}

export enum INSTALLATION_MODE {
  NORMAL = 0,
  VDI = 1,
  GOLDEN = 2,
  OFFLINE = 3,
}

export enum ALERT_STATUS {
  REJECTED,
  ACTIVE,
  RESOLVED,
}

export const AUTH_STATE = Object.freeze({
  ROLE_PRE_AUTH_CODE: "PRE_AUTH_CODE",
  ROLE_PRE_AUTH_SECRET: "PRE_AUTH_SECRET",
});

export enum ROLE {
  USER = "ROLE_USER",
  ADMIN = "ROLE_ADMIN",
  MANAGER = "ROLE_MANAGER",
  SERVICEDESK = "ROLE_SERVICEDESK",
  CLASSIFIED = "ROLE_CLASSIFIED",
  READONLY = "ROLE_READONLY",
}

export enum POSITION {
  FIRST = "first",
  LAST = "last",
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

/**
 * @readonly
 * @enum {string}
 */
export const WIDTH = Object.freeze({
  NO_WIDTH: "",
  XS: "xs",
  MD: "md",
  MD2X: "md2x",
  MD3X: "md3x",
  MD4X: "md4x",
  MD5X: "md5x",
  MD6X: "md6x",
  LG: "lg",
  XL: "xl",
  SM: "sm",
  SM2X: "sm2x",
});

export enum WIDTH_NG {
  "2XL" = "2xl",
  "3XL" = "3xl",
  "4XL" = "4xl",
  LG = "lg",
  MD = "md",
  SM = "sm",
  XL = "xl",
  XS = "xs",
}

export const EMPTY_VALUE = "...";

/**
 * @readonly
 * @enum {string}
 */
export const DEPLOYMENT_TYPE = Object.freeze({
  AGENCY: "agency",
  AGENT: "agent",
  VACCINE: "vaccine",
});

const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

/**
 * @readonly
 * @enum {string}
 */
export const TIME = Object.freeze({
  MILLISECOND,
  SECOND,
  MINUTE,
  HOUR,
  DAY,
});

/**
 * @readonly
 * @enum {string}
 */
export const ORDER = Object.freeze({
  ASC: "asc",
  DESC: "desc",
});

/**
 * @readonly
 * @enum {number}
 */
export const VIEW = Object.freeze({
  PRIVILEGED: "privileged-view",
  UNPRIVILEGED: "unprivileged-view",
});

export enum DISPLAY_STATES {
  ACTIVE = "active",
  DISABLED = "disabled",
  ERROR = "error",
  OK = "ok",
  WARNING = "warning",
}

export const DEFAULT_PAGINATOR_SIZES = [10, 20, 50];

export enum ISSUE_TYPE {
  QUESTION = 1,
  ERROR,
  IMPROVEMENT,
}
