var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.button)?_c('hr',{staticClass:"border-primary-lightest w-full mb-3"}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"filter-button-transition","enter-active-class":"animate__animated animate__bounceIn animate__muchfaster","leave-active-class":"animate__animated animate__fadeOut animate__muchfaster"}},[(_vm.visible)?_c('v-popover',{attrs:{"popover-class":"","popover-inner-class":"tooltip-inner popover-inner px-4 py-3","placement":"right","offset":"12","open":_vm.popoverOpened,"disabled":!_vm.popover},on:{"update:open":function($event){_vm.popoverOpened=$event}}},[_c('button',{staticClass:"h-6 w-6 rounded-full flex justify-center items-center transition-opacity duration-300 ease-in-out hover:opacity-75 focus:outline-none",class:{ 'bg-back-lightest': _vm.button },attrs:{"data-testid":("aside-link-" + _vm.icon)},on:{"click":_vm.onClick}},[(!_vm.loading)?_c('i',{staticClass:"transition-color duration-300 ease-in-out flex items-center justify-center h-full",class:[
            ("mc-" + _vm.icon),
            {
              'text-primary text-xl mt-px': _vm.button,
              'text-2xl': !_vm.button,
              'text-back-lightest':
                (!_vm.button && !_vm.popover) || (!_vm.button && _vm.popover && !_vm.popoverOpened),
              'text-primary-light': !_vm.button && _vm.popover && _vm.popoverOpened,
            } ],attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('span',{staticClass:"w-6 h-6"},[_c('spinner',{attrs:{"negative":true}})],1):_vm._e()]),_vm._v(" "),_c('template',{slot:"popover"},[_vm._t("default")],2)],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }