export const SET_SECTORS = "SET_SECTORS";
export const SET_SECTOR = "SET_SECTOR";
export const ADD_SECTOR = "ADD_SECTOR";
export const DELETE_SECTOR = "DELETE_SECTOR";
export const SET_CURRENT_SECTOR = "SET_CURRENT_SECTOR";
export const SET_CURRENT_AGENCY = "SET_CURRENT_AGENCY";
export const SET_AGENCIES = "SET_AGENCIES";
export const REMOVE_AGENCIES = "REMOVE_AGENCIES";
export const ADD_AGENCY = "ADD_AGENCY";
export const SET_AGENCY = "SET_AGENCY";
export const DELETE_AGENCY = "DELETE_AGENCY";
export const SET_AGENCY_LIST = "SET_AGENCY_LIST";
export const SET_SECTOR_LIST = "SET_SECTOR_LIST";
export const RESET_OPEN_AGENCIES = "RESET_OPEN_AGENCIES";
export const SET_INSTANCE_ID = "SET_INSTANCE_ID";
export const IS_FORM_BACKEND_VALIDATED = "IS_FORM_BACKEND_VALIDATED";
