/* eslint-disable max-lines */

import type { ActionTree, MutationTree } from "vuex";
import { PaginatedResponse, RequestConfigParams, agencyid, uuid4 } from "~/types/types";
import {
  OSDefinition,
  VaccineDTO,
  VaccineDefinition,
  VaccineSaveDTO,
} from "~/types/vaccine/vaccine";
import {
  ADD_VACCINE,
  DELETE_VACCINE,
  REMOVE_AGENCY_DEPLOYMENTS,
  REMOVE_AGENT_DEPLOYMENTS,
  REMOVE_ALL_AGENCY_DEPLOYMENTS,
  REMOVE_ALL_AGENT_DEPLOYMENTS,
  SET_AGENCY_DEPLOYMENTS,
  SET_AGENCY_DEPLOYMENT_STATUS,
  SET_AGENCY_DEPLOYMENT_STATUS_AUTORUN,
  SET_AGENT_DEPLOYMENTS,
  SET_AGENT_DEPLOYMENT_STATUS,
  SET_AGENT_DEPLOYMENT_STATUS_AUTORUN,
  SET_EDITING_VACCINE,
  SET_OSES,
  SET_TYPES,
  SET_VACCINE,
  SET_VACCINES,
  SET_VACCINE_LIST,
  SET_VACCINE_STATUS_AUTORUN,
} from "./vaccinesMutationTypes";

import { LIFECYCLE_STATE } from "~/assets/js/constants";
import { lifecycleToLifecycle } from "~/assets/js/logic/lifecycleToLifecycle";
import urlBuilder from "~/assets/js/urlBuilder";
import DownloadService from "~/services/DownloadService";
import { AgencyDeploymentDTO } from "~/types/deployment/agencyDeployment";
import { AgentDeploymentDTO } from "~/types/deployment/agentDeployment";
import { SET_LOADING } from "../sharedMutationTypes";

export const state = () => ({
  agencyDeployments: {} as { [key: string]: PaginatedResponse<AgencyDeploymentDTO> },
  agentDeployments: {} as { [key: string]: PaginatedResponse<AgentDeploymentDTO> },
  loading: false,
  vaccines: null as PaginatedResponse<VaccineDTO>,
  vaccineList: null as VaccineDTO[],
  allOses: [] as OSDefinition[],
  allTypes: [] as VaccineDefinition[],
  editingVaccine: null as VaccineDTO,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_AGENCY_DEPLOYMENT_STATUS](state, { agencyIds, vaccineId }) {
    const deploymentsCollection = state.agencyDeployments[vaccineId].collection;
    const index = deploymentsCollection.findIndex((deployment) => deployment.id === agencyIds[0]);

    const isDeploymentFound = index >= 0;

    if (isDeploymentFound) {
      const deployment = deploymentsCollection[index];

      const newStatus = lifecycleToLifecycle(deployment.status);

      deployment.status = newStatus;
      deploymentsCollection.splice(index, 1, deployment);
    }
  },

  [SET_AGENT_DEPLOYMENT_STATUS](state, { agentIds, vaccineId }) {
    const deploymentsCollection = state.agentDeployments[vaccineId].collection;
    const index = deploymentsCollection.findIndex((deployment) => deployment.id === agentIds[0]);

    const isDeploymentFound = index >= 0;

    if (isDeploymentFound) {
      const deployment = deploymentsCollection[index];

      const newStatus = lifecycleToLifecycle(deployment.status);

      deployment.status = newStatus;
      deploymentsCollection.splice(index, 1, deployment);
    }
  },

  [SET_AGENT_DEPLOYMENTS](
    state,
    {
      parent,
      agentDeployments,
    }: { parent: uuid4; agentDeployments: PaginatedResponse<AgentDeploymentDTO> },
  ) {
    this._vm.$set(state.agentDeployments, parent, agentDeployments);
  },

  [REMOVE_AGENT_DEPLOYMENTS](state, { parent }: { parent: uuid4 }) {
    this._vm.$delete(state.agentDeployments, parent);
  },

  [REMOVE_ALL_AGENT_DEPLOYMENTS](state) {
    for (const parent of Object.keys(state.agentDeployments)) {
      this._vm.$delete(state.agentDeployments, parent);
    }
  },

  [REMOVE_ALL_AGENCY_DEPLOYMENTS](state) {
    for (const parent of Object.keys(state.agencyDeployments)) {
      this._vm.$delete(state.agencyDeployments, parent);
    }
  },

  [SET_AGENCY_DEPLOYMENTS](
    state,
    {
      parent,
      agencyDeployments,
    }: { parent: uuid4; agencyDeployments: PaginatedResponse<AgentDeploymentDTO> },
  ) {
    this._vm.$set(state.agencyDeployments, parent, agencyDeployments);
  },

  [REMOVE_AGENCY_DEPLOYMENTS](state, { parent }: { parent: uuid4 }) {
    this._vm.$delete(state.agencyDeployments, parent);
  },

  [SET_AGENCY_DEPLOYMENT_STATUS_AUTORUN](
    state,
    { parent, value }: { parent: uuid4; value: boolean },
  ) {
    if (state.agencyDeployments[parent]) {
      for (const deployment of state.agencyDeployments[parent].collection) {
        const newStatus = value
          ? LIFECYCLE_STATE.NOT_APPLIED_REQUIRED
          : LIFECYCLE_STATE.NOT_APPLIED_NOT_REQUIRED;

        this._vm.$set(deployment, "status", newStatus);
      }
    }
  },

  [SET_AGENT_DEPLOYMENT_STATUS_AUTORUN](
    state,
    { parent, value }: { parent: uuid4; value: boolean },
  ) {
    if (state.agentDeployments[parent]) {
      for (const deployment of state.agentDeployments[parent].collection) {
        const newStatus = value
          ? LIFECYCLE_STATE.NOT_APPLIED_REQUIRED
          : LIFECYCLE_STATE.NOT_APPLIED_NOT_REQUIRED;

        this._vm.$set(deployment, "status", newStatus);
      }
    }
  },

  [ADD_VACCINE](state, vaccine: VaccineDTO) {
    state.vaccines.collection.unshift(vaccine);
  },

  [DELETE_VACCINE](state, id: uuid4) {
    const index = state.vaccines.collection.findIndex((vaccine) => vaccine.id === id);
    const isVaccineFound = index >= 0;

    if (isVaccineFound) {
      state.vaccines.collection.splice(index, 1);
    }
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_OSES](state, oses) {
    state.allOses = oses.collection;
  },

  [SET_TYPES](state, types: VaccineDefinition[]) {
    state.allTypes = types;
  },

  [SET_VACCINE](state, updatedVaccine: VaccineDTO) {
    const index = state.vaccines.collection.findIndex(
      (vaccine) => vaccine.id === updatedVaccine.id,
    );

    if (index >= 0) {
      state.vaccines.collection.splice(index, 1, updatedVaccine);
    }
  },

  [SET_VACCINE_STATUS_AUTORUN](state, { vaccineId, value }: { vaccineId: uuid4; value: boolean }) {
    const index = state.vaccines.collection.findIndex((vaccine) => vaccine.id === vaccineId);

    if (index >= 0) {
      const updatedVaccine = state.vaccines.collection[index];
      updatedVaccine.autorun = value;
      state.vaccines.collection.splice(index, 1, updatedVaccine);
    }
  },

  [SET_VACCINES](state, vaccines: PaginatedResponse<VaccineDTO>) {
    state.vaccines = vaccines;
  },

  [SET_EDITING_VACCINE](state, editingVaccine: VaccineDTO) {
    state.editingVaccine = editingVaccine;
  },

  [SET_VACCINE_LIST](state, vaccinesList: VaccineDTO[]) {
    state.vaccineList = vaccinesList;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async addVaccine({ commit }, savedVaccine: VaccineSaveDTO) {
    try {
      const createdVaccine = await this.$axios.$post<VaccineDTO>("vaccines", savedVaccine);
      commit(ADD_VACCINE, createdVaccine);
      this.$feedback.ok("feedback.create.vaccine.ok");
    } catch (error) {
      this.$feedback.error("feedback.create.vaccine.error");
    }
  },

  async setVaccine({ commit }, { savedVaccine, id }: { savedVaccine: VaccineSaveDTO; id: uuid4 }) {
    try {
      const vaccine = await this.$axios.$patch<VaccineDTO>(`vaccines/${id}`, savedVaccine);
      commit(SET_VACCINE, vaccine);
      this.$feedback.ok("feedback.update.vaccine.ok");
    } catch (error) {
      this.$feedback.error("feedback.update.vaccine.error");
    }
  },

  async deleteVaccine({ commit }, id: uuid4) {
    try {
      await this.$axios.delete(`vaccines/${id}`);
      commit(DELETE_VACCINE, id);
      this.$feedback.ok("feedback.delete.vaccine.ok");
    } catch (error) {
      this.$feedback.error("feedback.delete.vaccine.error");
    }
  },

  async stopVaccine(
    { rootGetters, commit },
    { vaccineId, agencyId, agentId }: { vaccineId: uuid4; agencyId?: agencyid; agentId?: uuid4 },
  ) {
    let endpoint = "";
    let executables: agencyid[] | uuid4[];

    const selectedAgency = rootGetters["application/getSelectedAgency"];
    const isInAgency = selectedAgency.agencyId !== undefined;

    if (isInAgency) {
      endpoint = `vaccines/${vaccineId}/agents/${agentId}/stop`;
      executables = [agentId];
    } else {
      endpoint = `vaccines/${vaccineId}/agencies/${agencyId}/stop`;
      executables = [agencyId];
    }

    try {
      await this.$axios.put(endpoint, executables);
      if (isInAgency) {
        commit(SET_AGENT_DEPLOYMENT_STATUS, {
          vaccineId,
          agentIds: executables,
        });
      } else {
        commit(SET_AGENCY_DEPLOYMENT_STATUS, {
          vaccineId,
          agencyIds: executables,
        });
      }
      this.$feedback.ok("feedback.stop.vaccine.ok");
    } catch (error) {
      this.$feedback.error("feedback.stop.vaccine.error");
    }
  },

  async executeVaccine(
    { rootGetters, commit },
    { vaccineId, agencyId, agentId }: { vaccineId: uuid4; agencyId?: agencyid; agentId?: uuid4 },
  ) {
    let endpoint = "";
    let executables: agencyid[] | uuid4[];

    const selectedAgency = rootGetters["application/getSelectedAgency"];
    const isInAgency = selectedAgency.agencyId !== undefined;

    if (isInAgency) {
      endpoint = `vaccines/${vaccineId}/agents/${agentId}/apply`;
      executables = [agentId];
    } else {
      endpoint = `vaccines/${vaccineId}/agencies/${agencyId}/apply`;
      executables = [agencyId];
    }

    try {
      await this.$axios.put(endpoint, executables);
      if (isInAgency) {
        commit(SET_AGENT_DEPLOYMENT_STATUS, {
          vaccineId,
          agentIds: executables,
        });
      } else {
        commit(SET_AGENCY_DEPLOYMENT_STATUS, {
          vaccineId,
          agencyIds: executables,
        });
      }
      this.$feedback.ok("feedback.execute.vaccine.ok");
    } catch (error) {
      this.$feedback.error("feedback.execute.vaccine.error");
    }
  },

  async fetch({ dispatch, commit }) {
    commit(SET_LOADING, true);
    try {
      await dispatch("setVaccines", { resetFolded: true });
      await dispatch("setOses");
      await dispatch("setTypes");
    } finally {
      commit(SET_LOADING, false);
    }
  },

  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },

  removeAllAgentDeployments({ commit }) {
    commit(REMOVE_ALL_AGENT_DEPLOYMENTS);
  },

  removeAllAgencyDeployments({ commit }) {
    commit(REMOVE_ALL_AGENCY_DEPLOYMENTS);
  },

  async updateAutorun(
    { rootGetters, commit },
    { vaccineId, value }: { vaccineId: uuid4; value: boolean },
  ) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const isAdminUser = rootGetters["application/isAdminUser"];
    try {
      const isInAgency = agencyId !== undefined;

      let updatedVaccine: VaccineDTO;

      if (isInAgency) {
        const action = value ? "apply" : "stop";
        updatedVaccine = await this.$axios.$put(
          `vaccines/${vaccineId}/agencies/${agencyId}/${action}`,
        );
        commit(SET_VACCINE_STATUS_AUTORUN, { vaccineId, value });
      } else if (!isAdminUser) {
        const action = value ? "applyToUserAgencies" : "stopFromUserAgencies";
        updatedVaccine = await this.$axios.$put(`vaccines/${vaccineId}/${action}`);
        commit(SET_VACCINE_STATUS_AUTORUN, { vaccineId, value });
      } else {
        updatedVaccine = await this.$axios.$patch(`vaccines/${vaccineId}`, {
          autorun: value,
        });
      }

      commit(SET_VACCINE, updatedVaccine);
      if (isInAgency) {
        commit(SET_AGENT_DEPLOYMENT_STATUS_AUTORUN, { parent: vaccineId, value });
      } else {
        commit(SET_AGENCY_DEPLOYMENT_STATUS_AUTORUN, { parent: vaccineId, value });
      }

      const text = value
        ? "feedback.selfexecute.activate.vaccine.ok"
        : "feedback.selfexecute.deactivate.vaccine.ok";
      this.$feedback.ok(text);
    } catch (error) {
      const text = value
        ? "feedback.selfexecute.activate.vaccine.error"
        : "feedback.selfexecute.deactivate.vaccine.error";
      this.$feedback.error(text);
      throw new Error(error);
    }
  },

  async fetchVaccine({ commit }, id: uuid4) {
    try {
      const editingVaccine = await this.$axios.$get<VaccineDTO>(`vaccines/${id}`);
      commit(SET_EDITING_VACCINE, editingVaccine);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setOses({ state, commit, rootGetters }) {
    const isAdmin = rootGetters["application/isAdmin"];
    if (isAdmin && !state.allOses.length) {
      try {
        const oses: OSDefinition[] = await this.$axios.$get("oses");
        commit(SET_OSES, oses);
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  async fetchAgencyDeployments(
    { commit, rootGetters },
    args: { vaccineId: uuid4; requestParams: RequestConfigParams },
  ) {
    const { vaccineId, requestParams } = args;
    const { page } = requestParams;
    const size = 10;

    const selectedAgency = rootGetters["application/getSelectedAgency"];
    const isInAgency = selectedAgency.agencyId !== undefined;

    const endpoint = isInAgency
      ? `agencies/${selectedAgency.agencyId}/vaccines/${vaccineId}/deployments`
      : `vaccines/${vaccineId}/deployments`;
    const apiUrl = urlBuilder(endpoint, {
      page,
      size,
    });

    try {
      const agencyDeployments = await this.$axios.$get<PaginatedResponse<AgencyDeploymentDTO>>(
        apiUrl,
      );
      commit(SET_AGENCY_DEPLOYMENTS, { parent: vaccineId, agencyDeployments });
    } catch (error) {
      throw new Error(error);
    }
  },

  async removeAgencyDeployments({ commit }, vaccineId: uuid4) {
    commit(REMOVE_AGENCY_DEPLOYMENTS, { parent: vaccineId });
  },

  async fetchAgentDeployments(
    { commit, rootGetters },
    args: { vaccineId: uuid4; requestParams: RequestConfigParams },
  ) {
    const { vaccineId, requestParams } = args;
    const { page } = requestParams;
    const size = 10;

    const selectedAgency = rootGetters["application/getSelectedAgency"];
    const endpoint = `agencies/${selectedAgency.agencyId}/vaccines/${vaccineId}/deployments`;

    const apiUrl = urlBuilder(endpoint, {
      page,
      size,
    });

    try {
      const agentDeployments = await this.$axios.$get<PaginatedResponse<AgentDeploymentDTO>>(
        apiUrl,
      );
      commit(SET_AGENT_DEPLOYMENTS, { parent: vaccineId, agentDeployments });
    } catch (error) {
      throw new Error(error);
    }
  },

  async removeAgentDeployments({ commit }, vaccineId: uuid4) {
    commit(REMOVE_AGENT_DEPLOYMENTS, { parent: vaccineId });
  },

  async setTypes({ commit, rootGetters, state }) {
    const isAdmin = rootGetters["application/isAdmin"];
    if (isAdmin && !state.allTypes.length) {
      try {
        const definitions: VaccineDefinition[] = await this.$axios.$get("vaccines/types");
        commit(SET_TYPES, definitions);
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  async setVaccineList({ commit }) {
    try {
      const vaccineList: VaccineDTO[] = await this.$axios.$get("vaccines/all");
      commit(SET_VACCINE_LIST, vaccineList);
    } catch (error) {
      throw new Error(error);
    }
  },

  async setVaccines({ commit, rootGetters }, args = {}) {
    const { sortBy, orderBy, page, filter } = args;
    const size = rootGetters["application/getPageSize"];
    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const endpoint = !agencyId ? `vaccines` : `agencies/${agencyId}/vaccines`;
    const apiUrl = urlBuilder(endpoint, {
      size,
      page,
      sortBy,
      orderBy,
      filter,
    });

    try {
      const vaccines: PaginatedResponse<VaccineDTO> = await this.$axios.$get(apiUrl);
      commit(SET_VACCINES, vaccines);
    } catch (error) {
      throw new Error(error);
    }
  },

  async exportVaccines() {
    try {
      const data = await this.$axios.$get("vaccine/export", { responseType: "blob" });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      const title = this.$i18n.t("vaccines.label") as string;
      link.setAttribute("download", `microclaudia - ${title.toLowerCase()}.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw new Error(error);
    }
  },

  async importVaccines({ dispatch }, args) {
    const { file } = args.payload;
    const formData = new FormData();
    formData.append("file", file);

    try {
      await this.$axios.$post(`/vaccines/uploadzip`, formData);
      dispatch("setVaccines");
      this.$feedback.ok("feedback.import.vaccine.ok");
    } catch (error) {
      this.$feedback.error("feedback.import.vaccine.error");
    }
  },

  async prepareCsv({ rootGetters, commit, dispatch }, requestParams: RequestConfigParams) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const endpoint = `/agencies/${agencyId}/vaccines/preparecsv`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },

  async prepareZip({ commit, dispatch }, requestParams: RequestConfigParams) {
    const endpoint = `/vaccines/preparezip`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },
};
