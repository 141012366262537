/* eslint-disable max-lines */

import {
  PaginatedResponse,
  TablePaginator,
  displayStatesUnion,
  stack,
  themesUnion,
} from "~/types/types";
import { vaccineInfo, vaccineInfoUnion } from "~/types/vaccine/vaccine";
import { LIFECYCLE_STATE, ROLE } from "./constants";

import { default as en } from "@/lang/en.json";
import { default as es } from "@/lang/es.json";
import { NuxtI18nInstance } from "@nuxtjs/i18n";
import VueI18n from "vue-i18n";
import { UIMessages } from "~/types/validation/validation";

export function isEllipsisActive(el: HTMLElement, safeMargin = 1) {
  // Store previous style so the calculation is accurate
  const previousStyle = el.style;

  // Reset style
  el.style.cssText = "border: 1px solid transparent";

  // check for overflow
  const isOverflowing = el.offsetWidth - safeMargin < el.scrollWidth;

  // restore previous style
  el.style.cssText = previousStyle.cssText;
  return isOverflowing;
}

export function debounce(fn: () => void, ms: number) {
  let timer: NodeJS.Timeout;
  return function (...args: unknown[]) {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 300);
  };
}

export function extractRole(credentialsStr: string) {
  const roles = credentialsStr.split(",").map((role) => role.trim());
  if (roles[1] && roles[1] === ROLE.ADMIN) {
    return roles[1];
  }
  if (roles[1] && roles[1] === ROLE.MANAGER) {
    return roles[1];
  }
  if (!roles[1]) {
    return roles[0];
  }
}

/**
 * It returns true if the role is included in the acl array.
 */
export function isAllowedTo(roleList: ROLE[], role: ROLE) {
  return roleList.includes(role);
}

export function getPrevNextChunck(start: number, end: number, arr: unknown[]) {
  return arr.slice(start - 1, start + end - 1);
}

/**
 * If the amount of pages is less than or equal to the maxElements, return the complete sequence.
 *
 * If the amount of pages is greater than the maxElements and the current page is less than 4, return the first 5
 * pages, -1, and the last page.
 *
 * If the amount of pages is greater than the maxElements and the
 * current page is greater than or equal to 4 and less than the amount of pages minus 4, return the first page,
 * -1, previous page, current page, next page, -1, and the last page.
 *
 *  If the amount of pages is greater than the maxElements and the current page is greater than or equal to 4 and greater than or equal to the
 * amount of pages minus 4, return the first page, -1, and the last 5 pages.
 * @returns An array of numbers.
 */
export function numberSequence() {
  const amountOfPages = this.pages;
  const middleBatchSize = 3;
  const completeSequence = [...Array(amountOfPages).keys()];
  let sequence = [];
  if (amountOfPages <= this.maxElements) {
    sequence = completeSequence;
  } else if (amountOfPages > this.maxElements && this.page < 4) {
    sequence = [...Array(5).keys(), -1, amountOfPages - 1];
  } else if (amountOfPages > this.maxElements && this.page >= 4 && this.page < amountOfPages - 4) {
    sequence = [
      0,
      -1,
      ...getPrevNextChunck(this.page, middleBatchSize, completeSequence),
      -1,
      amountOfPages - 1,
    ];
  } else if (amountOfPages > this.maxElements && this.page >= 4 && this.page >= amountOfPages - 4) {
    sequence = [0, -1, ...completeSequence.slice(-5)];
  }
  return sequence;
}

/**
 * It returns a function that returns a number between 0 and limit-1, cycling back to 0 when it reaches
 * limit-1
 * @param limit - The maximum value that the function will return.
 * @returns A function that returns a number between 0 and limit - 1, cycling back to 0 when it reaches
 * limit.
 */
export function cycledInt(limit: number) {
  let currentInt = -1;
  return function () {
    currentInt = (currentInt + 1) % limit;
    return currentInt;
  };
}

export function createPaginator<T>(
  items: PaginatedResponse<T>,
  i18n: NuxtI18nInstance,
  sizes?: number[],
): TablePaginator {
  const { total, page, size } = items;
  const paginator: TablePaginator = {
    total,
    page,
    size,
    language: i18n.locale,
  };

  if (sizes) {
    paginator.sizes = sizes;
  }

  return paginator;
}

export function toEmptyStateStatus(
  predicate: boolean,
  isFiltered: boolean,
): "empty" | "no-results" {
  if (!predicate && !isFiltered) {
    return "empty";
  }
  if (!predicate && isFiltered) {
    return "no-results";
  }
}

export function displayError(errorMesages: UIMessages, field: string, i18n: NuxtI18nInstance) {
  if (errorMesages[field] && errorMesages[field].length > 0) {
    return {
      error: true,
      errorMessage: errorMesages[field]
        .map((message) => i18n.t(`validations.${message}`))
        .join(", "),
    };
  }
  return { error: false, errorMessage: "" };
}

// https://rickschubert.net/blog/posts/flushing-promises
// https://github.com/kentor/flush-promises/blob/master/index.js
export function flushPromises() {
  const scheduler = typeof setImmediate === "function" ? setImmediate : setTimeout;

  return new Promise((resolve) => {
    scheduler(resolve);
  });
}

export function setupI18n(defaultLocale = "es") {
  const messages = { en, es };

  return new VueI18n({
    locale: defaultLocale,
    messages,
    dateTimeFormats: {
      en: {
        default: {},
      },
      es: {
        default: {},
      },
    },
  });
}

/**
 * It returns an invisible unicode character (U+00AD SOFT HYPHEN).
 * Used to force rendering of tooltip in state component.
 */
export function invisibleUnicodeChar() {
  return "\u{00AD}";
}

export function statusToTranslationKey(status: number) {
  const translations = {
    0: "generic.lifecycle.states.notAppliedRequired",
    1: "generic.lifecycle.states.applied",
    2: "generic.lifecycle.states.applied",
    3: "generic.lifecycle.states.notAppliedNotRequired",
    4: "generic.lifecycle.states.appliedWithError",
    5: "generic.lifecycle.states.appliedWithError",
    6: "generic.lifecycle.states.pending",
    7: "generic.lifecycle.states.pending",
    10: "generic.lifecycle.states.incompatible",
  };

  return translations[status];
}

export function statusToDisplayStateAndTheme(status: LIFECYCLE_STATE): {
  state: displayStatesUnion;
  theme?: themesUnion;
} {
  switch (status) {
    case LIFECYCLE_STATE.NOT_APPLIED_REQUIRED:
      return {
        state: "error",
        theme: "light",
      };

    case LIFECYCLE_STATE.APPLIED_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.APPLIED_NOT_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.APPLIED:
      return {
        state: "ok",
        theme: "medium",
      };
    case LIFECYCLE_STATE.NOT_APPLIED_NOT_REQUIRED:
      return {
        state: "disabled",
        theme: "medium",
      };
    case LIFECYCLE_STATE.APPLIED_WITH_ERROR:
    case LIFECYCLE_STATE.APPLIED_WITH_ERROR_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.APPLIED_WITH_ERROR_NOT_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.INCOMPATIBLE:
      return {
        state: "error",
        theme: "dark",
      };
    case LIFECYCLE_STATE.PENDING:
    case LIFECYCLE_STATE.PENDING_REQUIRED_LEGACY:
    case LIFECYCLE_STATE.PENDING_NOT_REQUIRED_LEGACY:
      return {
        state: "warning",
        theme: "medium",
      };
  }
}
export function vaccineInfoStateToDisplayStateAndTheme(vaccineInfoState: vaccineInfoUnion): {
  state: displayStatesUnion;
  theme?: themesUnion;
} {
  switch (vaccineInfoState) {
    case "notAppliedRequired":
    case "notApplied":
      return {
        state: "error",
        theme: "light",
      };
    case "appliedRequired":
    case "appliedNotRequired":
    case "applied":
      return {
        state: "ok",
        theme: "medium",
      };
    case "notAppliedNotRequired":
      return {
        state: "disabled",
        theme: "medium",
      };
    case "appliedWithError":
    case "appliedWithErrorNotRequired":
    case "appliedWithErrorRequired":
    case "incompatible":
      return {
        state: "error",
        theme: "dark",
      };
    case "pending":
    case "pendingNotRequired":
    case "pendingRequired":
      return {
        state: "warning",
        theme: "medium",
      };
  }
}

export function vaccineInfoToStack(vaccineInfo: vaccineInfo, i18n: NuxtI18nInstance): stack[] {
  const stack: stack[] = [];
  const translationRoot = "generic.lifecycle.states";

  for (const [key, value] of Object.entries(vaccineInfo)) {
    if (key !== "totalApplied" && key !== "total") {
      stack.push({
        id: key,
        count: value,
        label: i18n.t(`${translationRoot}.${key}`),
        ...vaccineInfoStateToDisplayStateAndTheme(key as vaccineInfoUnion),
      });
    }
  }

  return stack;
}
