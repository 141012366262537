import type { ActionTree, MutationTree } from "vuex";
import {
  REMOVE_AGENCIES,
  RESET_OPEN_AGENCIES,
  SET_AGENCIES,
  SET_INSTANCE_ID,
} from "~/store/agencies/agencyMutationTypes";
import { PaginatedResponse, RequestConfigParams, instanceid, semver, uuid4 } from "~/types/types";

import DownloadService from "~/services/DownloadService";
import VersionControlService from "~/services/VersionControlService";
import { AgencyDTO } from "~/types/agency/agency";
import { VersionDTO } from "~/types/version/version";
import { SET_LOADING } from "../sharedMutationTypes";
import { SET_VERSIONS } from "./versionMutationTypes";

export const state = () => ({
  versions: null as PaginatedResponse<VersionDTO>,
  agencies: {} as { [key: string]: PaginatedResponse<AgencyDTO> },
  loading: false,
  instanceId: null as instanceid,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [RESET_OPEN_AGENCIES](state) {
    state.agencies = {};
  },

  [SET_AGENCIES](
    state,
    { parent, agencies }: { parent: uuid4; agencies: PaginatedResponse<AgencyDTO> },
  ) {
    this._vm.$set(state.agencies, parent, agencies);
  },

  [REMOVE_AGENCIES](state, { parent }: { parent: uuid4 }) {
    this._vm.$delete(state.agencies, parent);
  },

  [SET_VERSIONS](state, versions: PaginatedResponse<VersionDTO>) {
    state.versions = versions;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_INSTANCE_ID](state, instanceId) {
    state.instanceId = instanceId;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetch({ dispatch, commit, rootGetters }) {
    commit(SET_LOADING, true);
    try {
      commit(RESET_OPEN_AGENCIES);
      const instanceId = rootGetters["application/getMainInstanceId"];
      commit(SET_INSTANCE_ID, instanceId);

      await dispatch("setVersions");
    } finally {
      commit(SET_LOADING, false);
    }
  },

  setLoading({ commit }, loading) {
    commit(SET_LOADING, loading);
  },

  async setVersions({ commit, rootGetters, state }, requestParams: RequestConfigParams = {}) {
    const size = rootGetters["application/getPageSize"];

    try {
      const versions = await VersionControlService.getVersions(this.$axios, state.instanceId, {
        ...requestParams,
        size,
      });
      commit(SET_VERSIONS, versions);
    } catch (error) {
      throw new Error(error);
    }
  },

  async fetchAgenciesByVersion(
    { commit, state },
    args: { versionId: semver; requestParams: RequestConfigParams },
  ) {
    const { versionId, requestParams } = args;

    try {
      const agencies = await VersionControlService.getAgenciesByVersion(
        this.$axios,
        state.instanceId,
        versionId,
        {
          ...requestParams,
          size: 10,
        },
      );
      commit(SET_AGENCIES, { parent: versionId, agencies });
    } catch (error) {
      throw new Error(error);
    }
  },

  async removeAgencies({ commit }, versionId: uuid4) {
    commit(REMOVE_AGENCIES, { parent: versionId });
  },

  async prepareCsv(
    { commit, dispatch, state },
    { version, requestParams = {} }: { version: semver; requestParams: RequestConfigParams },
  ) {
    const { instanceId } = state;
    const endpoint = `/instances/${instanceId}/versions/${version}/preparecsv`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },
};
