import { HttpClient, agencyid, instanceid } from "~/types/types";
import type { Commit } from "vuex";
import { FeedbackProvider } from "~/types/feedback/feedback";
import { AgencySaveDTO } from "~/types/agency/agency";
import { IS_FORM_BACKEND_VALIDATED } from "~/store/agencies/agencyMutationTypes";

export default class AgencyService {
  static async updateAllAgents(httpClient: HttpClient, instanceId: instanceid) {
    await httpClient.post(`/instances/${instanceId}/agencies/updateAgentAll`);
  }

  static async updateSelectedAgents(
    httpClient: HttpClient,
    instanceId: instanceid,
    agencies: agencyid[],
    actOnChildren: boolean,
  ) {
    await httpClient.post(`/instances/${instanceId}/agencies/updateAgent`, {
      agencies,
      updateAgenciesChildren: actOnChildren,
    });
  }

  static async setAgency(
    httpClient: HttpClient,
    savedAgency: AgencySaveDTO,
    id: agencyid,
    context: { commit: Commit; $feedback: FeedbackProvider },
  ) {
    const { commit, $feedback } = context;
    const apiUrl = `agencies/${id}`;
    commit(IS_FORM_BACKEND_VALIDATED, false);

    try {
      const response = await httpClient.patch(apiUrl, savedAgency);
      const agency = response.data;
      commit("SET_AGENCY", agency);
      commit(IS_FORM_BACKEND_VALIDATED, true);

      $feedback.ok("feedback.update.agency.ok");
    } catch (error) {
      if (error.response.data.message === "badHierarchy") {
        commit(IS_FORM_BACKEND_VALIDATED, false);
        const { error: prefix, message } = error.response.data;
        $feedback.error(`${prefix}${message}`);
      } else {
        $feedback.error("feedback.update.agency.error");
      }
    }
  }
}
