import type { ActionTree, MutationTree } from "vuex";
import urlBuilder from "~/assets/js/urlBuilder";
import { PaginatedResponse, RequestConfigParams } from "~/types/types";
import { SET_LOADING } from "@/store/sharedMutationTypes";
import { TelemetryEntryDTO } from "~/types/telemetry/telemetry";
import { SET_TELEMETRY_ENTRIES } from "./telemetryMutationTypes";
import DownloadService from "~/services/DownloadService";

export const state = () => ({
  telemetryEntries: null as PaginatedResponse<TelemetryEntryDTO>,
  loading: false,
});

type RootState = ReturnType<typeof state>;

export const mutations: MutationTree<RootState> = {
  [SET_TELEMETRY_ENTRIES](
    state: RootState,
    telemetryEntries: PaginatedResponse<TelemetryEntryDTO>,
  ) {
    state.telemetryEntries = telemetryEntries;
  },
  [SET_LOADING](state: RootState, loading: boolean) {
    state.loading = loading;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async fetch({ dispatch, commit }, args) {
    commit(SET_LOADING, true);
    try {
      await dispatch("setTelemetryEntries", args);
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async setTelemetryEntries({ commit, rootGetters }, requestParams: RequestConfigParams = {}) {
    const { sortBy, orderBy, page, filter } = requestParams;
    const size = rootGetters["application/getPageSize"];

    const { agencyId } = rootGetters["application/getSelectedAgency"];

    const endpoint = !agencyId ? `telemetry` : `agencies/${agencyId}/telemetry`;

    const apiUrl = urlBuilder(endpoint, {
      size,
      page,
      sortBy,
      orderBy,
      filter,
    });

    try {
      const telemetryEntries: PaginatedResponse<TelemetryEntryDTO> = await this.$axios.$get(apiUrl);
      commit(SET_TELEMETRY_ENTRIES, telemetryEntries);
    } catch (error) {
      throw new Error(error);
    }
  },

  async prepareCsv({ rootGetters, commit, dispatch }, requestParams: RequestConfigParams) {
    const { agencyId } = rootGetters["application/getSelectedAgency"];
    const endpoint = `agencies/${agencyId}/telemetry/preparecsv`;

    await DownloadService.prepareDownload(this.$axios, endpoint, requestParams, {
      dispatch,
      commit,
      $feedback: this.$feedback,
    });
  },
};
